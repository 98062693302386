import React from "react";
import { Outlet } from "react-router-dom";
import FrontendFooter from "../Common/FrontendFooter/FrontendFooter";
import FrontendHeader from "../Common/FrontendHeader/FrontendHeader";   



import "./FrontendLayout.css";

const FrontendLayout = () => {
  return (
    <>
      <div className="mainContent">
        <div id="container">
          <div id="header">
            <FrontendHeader />
          </div>
          <div className="medigrade_body">
          <div id="body" className="pageMain">
            <div className="nodatadashboard">
              <div className="maincontentinnerpage">
                <Outlet />
              </div>
            </div>
          </div>
          </div>
          <div id="footer">
            <FrontendFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontendLayout;