import React from "react";
import "../FrontendHeader/FrontendHeader.css";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, Navigate, NavLink, Outlet, useLocation } from 'react-router-dom'









const FrontendHeader = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




    return (
        <>
            <div className="headersection">
                <div className="mainwrapper">
                    <div className="">
                        <div className="flex_wrapper">
                            <div className="logowrapper">
                                <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/medigrade_header_logo.png" />

                            </div>
                            <div className="num_menu_wrapper">
                                <div className="header_inner_wrp">
                                    <a href="tel:(866) 589-8639"><h2> (866) 589-8639</h2></a>
                                </div>


                                <div className="header_button_sec">
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <div className="quotebutton">
                                            <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/header_round_menu.png"/>
                                        </div>
                                    </Button>
                                    <div className="headermenu_inner_wrp">

                                        <Menu className="header_menu"
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >                                           
                                            <NavLink exact='true' to={"/"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>HOME</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/why-medigrade"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>WHY MEDIGRADE</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/tech-software"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>TECH & SOFTWARE</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/for-agencies"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>FOR AGENCIES</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/blog"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>BLOG</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/about-us"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>ABOUT US</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/results"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>RESULTS</span></MenuItem></NavLink>
                                            <NavLink exact='true' to={"/contact-us"} ><MenuItem className="header_menu_item" onClick={handleClose}><span>CONTACT US</span></MenuItem></NavLink>
                                       
                                        </Menu>
                                    </div>

                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default FrontendHeader;