import React from "react";
import "./FrontendFooter.css";
import ScrollToTop from "react-scroll-to-top";

const FrontendFooter = () => {
  return (
    <>
      <ScrollToTop smooth className="scroll_top_button" />

      <div className="footer_main">
        <div className="mainwrapper">
          <div className="footer_inner_wrp">
            <div className="footer_logo">
              <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/medigrade_footer_logo.png" />
            </div>
            <div className="footer_about">
              <h2>About Us</h2>
              <p>
                Medigrade Marketing is a part of the larger Medigrade and even
                Larger Beto Paredes Family of Companies. Having been in software
                and marketing for over 20 years, we’re a little obsessed with
                both. We are now using our obsessions to pursue our passions of
                better healthcare and better resources for patients and
                physicians.
              </p>
            </div>
            <div className="footer_contact">
              <h2>Contact Information</h2>
              <button>CONTACT US</button>
              <h5>Operating Hours</h5>
              <h6>Mon-Sat: 9 am – 7 pm </h6>
              <h6>Sunday: 9 am – 6 pm</h6>
            </div>
            <div className="footer_social">
              <h2>Our Social Media</h2>
              <div className="social_img">
                <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/facebook_img.png" />
                <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/twitter_img.png" />
                <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/linked_img.png" />
                <img src="https://all-frontend-assets.s3.amazonaws.com/Medigrade_marketing/assets/images/t_img.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="footerbottom_sec">
          <div className="footer_bottom_wrp">
            <div className="footerbottom_flex">
              <h5>Terms & Conditions </h5>
              <h5>Privacy Policy</h5>
              <h5>Copyright © 2019 WAS. All Rights Reserved</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontendFooter;
