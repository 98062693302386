import React, { Suspense } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FrontendLayout from './components/Frontend/FrontendLayout';

// import Home from './components/Frontend/Home/Home';
// import Tech from './components/Frontend/Tech/Tech';
// import For_agencies from './components/Frontend/For_agencies/For_agencies';
// import About_us from './components/Frontend/About_us/About_Us';
// import Blog from './components/Frontend/Blog/Blog';
// import ContactUs from './components/Frontend/ContactUs/Contact-Us';
// import WhyMedigrade from './components/Frontend/WhyMedigrade/WhyMedigrade';
// import Results from './components/Frontend/Results/Results';

const Home = React.lazy(() => import('./components/Frontend/Home/Home'))
const Tech = React.lazy(() => import('./components/Frontend/Tech/Tech'))
const For_agencies = React.lazy(() => import('./components/Frontend/For_agencies/For_agencies'))
const About_us = React.lazy(() => import('./components/Frontend/About_us/About_Us'))
const Blog = React.lazy(() => import('./components/Frontend/Blog/Blog'))
const ContactUs = React.lazy(() => import('./components/Frontend/ContactUs/Contact-Us'))
const WhyMedigrade = React.lazy(() => import('./components/Frontend/WhyMedigrade/WhyMedigrade'))
const Results = React.lazy(() => import('./components/Frontend/Results/Results'))




function App() {
  return (
      <BrowserRouter>
        <Routes>
          {/* frontend*/}
          <Route path="/" element={<FrontendLayout />}>

            {/* <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/tech-software" element={<Tech />} />
            <Route path="/for-agencies" element={<For_agencies />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about-us" element={<About_us />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/why-medigrade' element={<WhyMedigrade />} />
            <Route path="/results" element={<Results />} /> */}

            <Route index element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path="/home" element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path="/tech-software" element={<Suspense fallback={<></>}><Tech /></Suspense>} />
            <Route path="/for-agencies" element={<Suspense fallback={<></>}><For_agencies /></Suspense>} />
            <Route path="/blog" element={<Suspense fallback={<></>}><Blog /></Suspense>} />
            <Route path="/about-us" element={<Suspense fallback={<></>}><About_us /></Suspense>} />
            <Route path='/contact-us' element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />
            <Route path='/why-medigrade' element={<Suspense fallback={<></>}><WhyMedigrade /></Suspense>} />
            <Route path="/results" element={<Suspense fallback={<></>}><Results /></Suspense>} />

            

          </Route>
        </Routes>
      </BrowserRouter>  
  )
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();